import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LandingComponent } from './pages/landing/landing.component';
import { DocumentationComponent } from './pages/documentation/documentation.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { SignupComponent } from './pages/signup/signup.component';
import { LoginComponent } from './pages/login/login.component';
import { PortalComponent } from './pages/portal/portal.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { NgxSpinnerModule } from "ngx-spinner";
import { ModalModule } from 'ngb-modal';
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignupVerifyComponent } from './pages/signup-verify/signup-verify.component';



// var config = {
//   apiKey: "AIzaSyD3y8EQSTukQkUox6HpbjPR5GrN8QriqwQ",
//   authDomain: "YOUR_AUTH_DOMAIN",
//   databaseURL: "https://context-point.firebaseio.com",
//   projectId: "context-point",
//   storageBucket: "YOUR_STORAGE_BUCKET",
//   messagingSenderId: "YOUR_MESSAGING_SENDER_ID"
// };

const firebaseConfig = {
  apiKey: "AIzaSyD3y8EQSTukQkUox6HpbjPR5GrN8QriqwQ",
  authDomain: "context-point.firebaseapp.com",
  databaseURL: "https://context-point.firebaseio.com",
  projectId: "context-point",
  storageBucket: "context-point.appspot.com",
  messagingSenderId: "754149583378",
  appId: "1:754149583378:web:be060fa8e47c0626e346cb"
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LandingComponent,
    DocumentationComponent,
    PricingComponent,
    SignupComponent,
    LoginComponent,
    PortalComponent,
    ForgotPasswordComponent,
    SignupVerifyComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgxSpinnerModule,
    ModalModule,
    ChartsModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
