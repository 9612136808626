import { Component, OnInit } from '@angular/core';
import { ROUTES } from '../../routes';

@Component({
  selector: 'pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  readonly routes = ROUTES;
  constructor() { }

  ngOnInit() {
  }

}
