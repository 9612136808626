import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ValidationService } from '../../services/validation.service'
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ModalManager } from 'ngb-modal';
import { ROUTES } from '../../routes';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SignupComponent implements OnInit {
  @ViewChild('signupModal', { static: false }) signupModal;
  @ViewChild('passwordPopper', { static: false }) passwordPopper: NgbPopover;
  @ViewChild('emailPopper', { static: false }) emailPopper: NgbPopover;
  @ViewChild('lastNamePopper', { static: false }) lastNamePopper: NgbPopover;
  @ViewChild('firstNamePopper', { static: false }) firstNamePopper: NgbPopover;

  readonly routes = ROUTES;
  private modalRef: ModalManager;
  signupForm: any;
  toggleAgreement: boolean = true;
  registrationMessage: String = undefined;

  hasSignupEror: boolean = false;

  invalidEmail: string = "Invalid Email";
  invalidFirstName: string = "Invalid First Name";
  invalidLastName: string = "Invalid Last Name";
  invalidPassword: string = "Invalid Password";
  invalidEmailDesc: string = "Enter a valid email address";
  invalidFirstNameDesc: string = "Enter a valid first name";
  invalidLastNameDesc: string = "Enter a valid last name";
  invalidPasswordDesc: string = "Enter a valid password";

  constructor(private formbulider: FormBuilder,
    private validationService: ValidationService,
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private modalService: ModalManager) { }

  ngOnInit() {
    this.signupForm = this.formbulider.group({
      // firstName: "",
      // lastName: "",
      password: "",
      email: ""
    });
  }
  toggleCheckbox() {
    this.toggleAgreement = !this.toggleAgreement;
  }
  togglePopover(): void {
    var nextInvalid = this.getNextInvalidField();

    if (nextInvalid == "email") {
      this.emailPopper.open();
    }
    else if (nextInvalid == "password") {
      this.passwordPopper.open();
    }
    // else if (nextInvalid == "lastName") {
    //   this.lastNamePopper.open();
    // }
    // else if (nextInvalid == "firstName") {
    //   this.firstNamePopper.open();
    // }
    else {

      // this.authService.register(this.signupForm.controls.email.value,
      //   this.signupForm.controls.password.value);
      this.signup();
      // this.router.navigateByUrl(this.routes.PATH_SIGNIN);
    }
  }
  async signup() {
    this.spinner.show();
    try {
      await this.authService.register(this.signupForm.controls.email.value,
        this.signupForm.controls.password.value);
      this.registrationMessage = `You have been sent a confirmation email. 
        Follow the instructions in the email to complete your registration.`;
      await this.authService.sendVerificationEmail();

      // var email = this.signupForm.controls.email.value;
      // this.router.navigateByUrl(this.routes.PATH_CONFIRM, { state: { email: email } });
      this.openModal();
      window.localStorage.setItem('emailForSignIn', this.signupForm.controls.email.value);
      // await this.authService.setUserData(result.user);
      // return user;
      
      this.spinner.hide();
      // this.router.navigateByUrl(this.routes.PATH_SIGNIN);
      this.hasSignupEror = false;
      
    }
    catch (error) {
      this.registrationMessage = error.message;
      console.log(error.message)
      this.spinner.hide();
      
      // this should be on the sign in page. check to see if it's a confirmation sign in first, then display
      this.hasSignupEror = true;
      this.openModal();
      // this.router.navigateByUrl(this.routes.PATH_SIGNIN);
    }
  }
  getNextInvalidField(): string {
    var form = this.signupForm.controls;
    var service = this.validationService;

    // if (!service.isNameValid(form.firstName.value)) {
    //   return "firstName";
    // }
    // else if (!service.isNameValid(form.lastName.value)) {
    //   return "lastName";
    // }
    if (!service.isEmailValid(form.email.value)) {
      return "email";
    }
    else if (!service.isPasswordValid(form.password.value)) {
      return "password";
    }
  }
  openModal() {
    this.modalRef = this.modalService.open(this.signupModal, {
      size: "md",
      modalClass: 'signupModal',
      hideCloseButton: false,
      centered: true,
      backdrop: true,
      animation: true,
      keyboard: true,
      closeOnOutsideClick: false,
      backdropClass: "modal-backdrop"
    })
  }
  closeModal() {
    this.modalService.close(this.modalRef);
    //or this.modalRef.close();

    // once modal is closed, navigate to sign-in page if 
    // there was no error
    if(! this.hasSignupEror) {
    this.router.navigateByUrl(this.routes.PATH_HOME);
    }
    else {
      // what to do? Stay on same page? Leave email/password fields populated?
    }
  }
}
