import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentationComponent } from './pages/documentation/documentation.component';
import { LandingComponent } from './pages/landing/landing.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { SignupComponent } from './pages/signup/signup.component';
import { LoginComponent } from './pages/login/login.component';
import { PortalComponent } from './pages/portal/portal.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SignupVerifyComponent } from './pages/signup-verify/signup-verify.component';
import { ROUTES } from './routes';

const routes: Routes = [
  { path: ROUTES.PATH_HOME, pathMatch: 'full', component: LandingComponent },
  { path: ROUTES.PATH_DOCUMENTATION, component: DocumentationComponent },
  { path: ROUTES.PATH_PRICING, component: PricingComponent },
  { path: ROUTES.PATH_SIGNUP, component: SignupComponent },
  { path: ROUTES.PATH_SIGNUP_VERIFY, component: SignupVerifyComponent },
  { path: ROUTES.PATH_SIGNIN, component: LoginComponent },
  { path: ROUTES.PATH_PORTAL, component: PortalComponent },
  { path: ROUTES.PATH_FORGOT_PASSWORD, component: ForgotPasswordComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
