// import { Component, OnInit } from '@angular/core';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ValidationService } from '../../services/validation.service'
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ModalManager } from 'ngb-modal';
import { ROUTES } from '../../routes';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  signupForm: any;
  constructor(private formbulider: FormBuilder) {
   }

   ngOnInit() {
    this.signupForm = this.formbulider.group({
      email: ""
    });
  }

  togglePopover(): void {
    console.log(this.signupForm.controls.email.value);
  }

}
