import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'signup-verify',
  templateUrl: './signup-verify.component.html',
  styleUrls: ['./signup-verify.component.css']
})
export class SignupVerifyComponent implements OnInit {

  constructor(private authService: AuthService) { 
  }

  ngOnInit() {
    console.log("from verify email page, email is verified = " + this.authService.confirmLoggedIn());
    // this.authService.confirmEmail(window.location.href);
    // console.log(window.location.href);
  }

}
