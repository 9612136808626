import { Injectable } from '@angular/core';
import { User } from "../services/user";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
// import { AngularFireDatabaseModule } from "@angular/fire/database";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData: any; // Save logged in user data
  // accountCreationError: boolean = true;

  actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: 'https://intelspin.com/signup-verify',
    // This must be true.
    handleCodeInApp: true,
    iOS: {
      bundleId: 'com.example.ios'
    },
    android: {
      packageName: 'com.example.android',
      installApp: true,
      minimumVersion: '12'
    },
    dynamicLinkDomain: 'example.page.link'
  };

  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth) {
    afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        localStorage.setItem('user', null);
      }
    })
  }

  // logIn(email: string, password: string) { }

  async register(email: string, password: string) {
    await this.afAuth.auth.createUserWithEmailAndPassword(email, password)
    .then(() => {
      this.afAuth.auth.currentUser.reload().then(() => {
        // console.log({ emailVerified: currentUser.emailVerified })
      });
    })
    .catch(function (error) {
      console.error("Auth Service - Error Code: " + error.code);
      console.error("Auth Service - Error Message: " + error.message);
    });
  }

  async sendVerificationEmail() {
    await this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
        console.log("sent auth email");

        const user = this.afAuth.auth.currentUser;
        user.reload().then(() => {
          console.log({ emailVerified: user.emailVerified })
        })
      })
    // console.log("current user in send verification email:" + this.afAuth.auth.currentUser);

    // this.afAuth.auth.sendSignInLinkToEmail("rbivens@geographyinmotion.com", this.actionCodeSettings)
    //   .then(function () {
    //     // The link was successfully sent. Inform the user.
    //     // Save the email locally so you don't need to ask the user for it again
    //     // if they open the link on the same device.
    //     window.localStorage.setItem('emailForSignIn', "rbivens@geographyinmotion.com");
    //   })
    //   .catch(function (error) {
    //     // Some error occurred, you can inspect the code: error.code
    //   });
  }

  isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  setUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  confirmLoggedIn() {
    this.afAuth.auth.currentUser.reload().then(() => {
      return this.afAuth.auth.currentUser.emailVerified;
    });
  }

  confirmEmail(href: string) {

    // this.afAuth.auth.currentUser.reload();
    // Confirm the link is a sign-in with email link.
    if (this.afAuth.auth.isSignInWithEmailLink(href)) {
      console.log("The link is a signup link, URL = " + href);
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      this.afAuth.auth.signInWithEmailLink(email, href)
        .then(function (result) {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function (error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
    else {
      console.log("The link is NOT a signup link, URL = " + href);
    }
  }

}
