import { Component, OnInit } from '@angular/core';
import { ROUTES } from '../../routes';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.css']
})
export class PortalComponent implements OnInit {
  readonly routes = ROUTES;
  constructor() { }

  ngOnInit() {
  }

}
