import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ValidationService } from '../../services/validation.service'
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ModalManager } from 'ngb-modal';
import { ROUTES } from '../../routes';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  @ViewChild('signupModal', { static: false }) signupModal;
  @ViewChild('passwordPopper', { static: false }) passwordPopper: NgbPopover;
  @ViewChild('emailPopper', { static: false }) emailPopper: NgbPopover;

  readonly routes = ROUTES;
  private modalRef: ModalManager;
  signupForm: any;
  loginMessage: String = undefined;

  invalidEmail: string = "Invalid Email";
  invalidPassword: string = "Invalid Password";
  invalidEmailDesc: string = "Enter a valid email address";
  invalidPasswordDesc: string = "Enter a valid password";

  constructor(private formbulider: FormBuilder,
    private validationService: ValidationService,
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private modalService: ModalManager) { }

  ngOnInit() {
    this.signupForm = this.formbulider.group({
      password: "",
      email: ""
    });
  }
  togglePopover(): void {
    var nextInvalid = this.getNextInvalidField();

    if (nextInvalid == "email") {
      this.emailPopper.open();
    }
    else if (nextInvalid == "password") {
      this.passwordPopper.open();
    }
    else {
      // this.authService.register(this.signupForm.controls.email.value,
      //   this.signupForm.controls.password.value);
      // this.login();
      
    }
  }
  // async login() {
  //   this.spinner.show();
  //   try {
  //     const result = await this.authService.register(this.signupForm.controls.email.value,
  //       this.signupForm.controls.password.value);
  //     // this.registrationMessage = `You have been sent a confirmation email. 
  //     //   Follow the instructions in the email to complete your registration.`;
  //     await this.authService.sendVerificationEmail();

  //     var email = this.signupForm.controls.email.value;
  //     // this.router.navigateByUrl(this.routes.PATH_CONFIRM, { state: { email: email } });
      
  //     this.openModal();
  //     // await this.authService.setUserData(result.user);
  //     // return user;
  //     this.spinner.hide();
  //   }
  //   catch (error) {
  //     this.loginMessage = error.message;
  //     console.log(error.message)
  //     this.spinner.hide();
  //     this.openModal();
  //   }
  // }
  getNextInvalidField(): string {
    var form = this.signupForm.controls;
    var service = this.validationService;

    if (!service.isEmailValid(form.email.value)) {
      return "email";
    }
    else if (!service.isPasswordValid(form.password.value)) {
      return "password";
    }
  }
  openModal() {
    this.modalRef = this.modalService.open(this.signupModal, {
      size: "md",
      modalClass: 'signupModal',
      hideCloseButton: false,
      centered: true,
      backdrop: true,
      animation: true,
      keyboard: true,
      closeOnOutsideClick: false,
      backdropClass: "modal-backdrop"
    })
  }
  closeModal() {
    this.modalService.close(this.modalRef);
    //or this.modalRef.close();
  }
}

