import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  isEmailValid(email: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  isNameValid(name: string): boolean {
    return !(name.length == 0);
  }

  isPasswordValid(password: string): boolean{
    return /^[A-Za-z]\w{7,40}$/.test(password);
  }
}
